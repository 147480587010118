import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountType } from '../account-type';
import { CurrentUserService } from './current-user.service';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AccountTypeService {

  constructor(private httpClient: HttpClient, private userService: CurrentUserService) { }

  getAccountTypes(): Observable<AccountType[]> {
    return this.userService.currentUser$.pipe(
      switchMap((user) =>  this.httpClient.get<AccountType[]>(
        `${environment.api}/business/${user.currentBusiness.id}/account-type`,
        {headers: {'session-id': user.sessionId}}
      ))
    );
  }
}
